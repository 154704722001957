import { useCallback, useEffect, useState } from "react";
import type { Dispatch, SetStateAction } from "react";

import { useLogin } from "../../hooks/context/login";
import { useDymoService } from "../../hooks/use-dymo-service";
// Hooks
import useKeyEvent from "../../hooks/use-key-event";
import useTimeout from "../../hooks/use-timeout";
// Utils
import { printShippingLabel } from "../../utils/dymo-print";
// Components
import ScanRow from "../components/scan-row";
import "./fulfillment.scss";
import BaseButton from "../base/button";

const { VITE_APP_FULFILLMENT_API_URI } = import.meta.env;

interface OrderData {
  orderId: string;
  kitName: string;
  shippingType: string;
  tracking: string;
  scannedTracking: string;
}
const OrderDataInitialState: OrderData = {
  orderId: "",
  kitName: "",
  shippingType: "",
  tracking: "",
  scannedTracking: "",
};

interface QRCodeJSON {
  salesOrderId: string;
  brand: string;
  boxProductName: string;
  boxVariantName: string;
  channel: string;
}

const getShippingLabel = async (
  scan: string,
  usertoken: string,
  onComplete: (data: OrderData) => void,
  onCompleteLogShipped: OnCompleteLogShipped,
  onError: (errorMessage: string) => void,
): Promise<void> => {
  try {
    let orderId: string, name: string, variant: string, channel: string;
    try {
      const {
        salesOrderId,
        boxProductName,
        boxVariantName,
        channel: channelHold,
      } = JSON.parse(scan) as QRCodeJSON;
      console.log(
        JSON.stringify({
          salesOrderId,
          boxProductName,
          boxVariantName,
          channelHold,
        }),
      );
      orderId = salesOrderId;
      name = boxProductName;
      variant = boxVariantName;
      channel = channelHold;
    } catch (error) {
      console.error(error);
      throw new Error(`Invalid Scan: '${scan}'. Retry QR code`);
    }

    if (channel === "HSN" || channel === "QVC") {
      return logShipped(orderId, usertoken, onCompleteLogShipped);
    } else if (channel === "CyberWeek") {
      // it's convenient to use onCompleteLogShipped here
      return onCompleteLogShipped({
        success: false,
        errorMessage: "Cannot process Cyber Week booklets in this App.",
      });
    }

    const req = await fetch(
      `${VITE_APP_FULFILLMENT_API_URI}/shipment/${orderId}/purchase`,
      {
        method: "POST",
        headers: new Headers({
          Authorization: `Bearer ${usertoken}`,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          channel,
        }),
      },
    );
    console.log(req);
    const payload = await req.json();

    if (!req.ok) {
      let err =
        payload?.errorMessage ??
        payload?.message ??
        "Unable to get shipping label.";
      throw new Error(`[${req.status}]: ${err}`);
    }
    if (payload.status === "canceled") {
      return onError("canceled");
    }
    const printResult = await printShippingLabel(payload.labelImage);
    if (!printResult.printComplete) {
      throw new Error(
        payload.errorMessage ?? "Unable to print shipping label.",
      );
    }

    onComplete({
      orderId,
      kitName: `${name} - ${variant}`,
      tracking: payload.tracking,
      shippingType: payload.shippingType,
      scannedTracking: "",
    });
  } catch (error) {
    console.error(error);
    onError(error.message);
  }
};

interface OnCompleteLogShipped {
  (param: { success: boolean; errorMessage?: string }): void;
}
const logShipped = async (
  orderId: string,
  usertoken: string,
  onComplete: OnCompleteLogShipped,
): Promise<void> => {
  try {
    const result = await fetch(
      `${VITE_APP_FULFILLMENT_API_URI}/${orderId}/shipped`,
      {
        method: "POST",
        headers: new Headers({
          Authorization: `Bearer ${usertoken}`,
        }),
      },
    );
    const payload = await result.json();
    onComplete({
      success: result.ok && !payload?.errorMessage,
      errorMessage: payload?.errorMessage,
    });
  } catch (error) {
    onComplete({
      success: false,
    });
  }
};
const trimQuotes = (scan: string) => {
  const startIndex = Number(scan[0] === `"`),
    endIndex = scan.length - Number(scan[scan.length - 1] === `"`);
  return scan.substring(startIndex, endIndex);
};

export const validateTracking = (tracking: string, scan: string): boolean => {
  let validTracking = false;
  // UPS label barcodes match tracking number exactly
  if (scan.substring(0, 2).toUpperCase() === "1Z") {
    validTracking = scan === tracking;
  } else {
    // FedEx label barcodes end with tracking number
    validTracking = new RegExp(`.*${tracking}$`).test(scan);
  }
  return validTracking;
};

/**
 * Hook to retrieve a shipping label from the Fulfillment API
 * when a booklet QR code is scanned
 */
const useScanBooklet = (
  resetKeyEvent: () => void,
  scanDone: boolean,
  setError: Dispatch<SetStateAction<string>>,
  setOrderData: Dispatch<SetStateAction<OrderData>>,
  setStep: Dispatch<SetStateAction<FulfillmentStep>>,
  step: FulfillmentStep,
  usertoken: string,
  val: string,
  toggleModal: () => void,
) => {
  /** Successfully retrieved a shipping label */
  const onGetLabelSuccess = useCallback(
    (data: OrderData) => {
      resetKeyEvent();
      setOrderData(data);
      setStep("SCAN_LABEL");
      setError(null);
    },
    [resetKeyEvent, setOrderData, setStep, setError],
  );
  /** Logged order as shipped that doesn't require an outbound label (wholesale etc.) */
  const onShipmentLoggedSuccess = useCallback(
    (logShippedResult) => {
      resetKeyEvent();
      setStep("SCAN_BOOKLET");
      setOrderData(OrderDataInitialState);

      if (logShippedResult.success) {
        setError(null);
      } else {
        setError(
          logShippedResult.errorMessage ?? "Invalid scan. Please try again.",
        );
      }
    },
    [resetKeyEvent, setOrderData, setStep, setError],
  );
  /** Error while getting a shipping label */
  const onLabelError = useCallback(
    (errorMessage: string) => {
      if (errorMessage === "canceled") {
      }

      resetKeyEvent();
      setError(errorMessage);
      setStep("SCAN_BOOKLET");
      setOrderData(OrderDataInitialState);
    },
    [resetKeyEvent, setOrderData, setStep, setError],
  );
  useEffect(() => {
    if (!scanDone || val === "" || step !== "SCAN_BOOKLET") {
      return;
    }
    getShippingLabel(
      trimQuotes(val),
      usertoken,
      onGetLabelSuccess,
      onShipmentLoggedSuccess,
      onLabelError,
    );
  }, [
    onGetLabelSuccess,
    onLabelError,
    onShipmentLoggedSuccess,
    scanDone,
    step,
    usertoken,
    val,
  ]);
};

/**
 * Hook to verify the tracking number on a label
 * and send a request to log order as Initial Kit Shipped
 */
const useScanTracking = (
  clearFilled: () => void,
  clearInput: () => void,
  orderData: OrderData,
  resetKeyEvent: () => void,
  scanDone: boolean,
  setError: Dispatch<SetStateAction<string>>,
  setOrderData: Dispatch<SetStateAction<OrderData>>,
  setStep: Dispatch<SetStateAction<FulfillmentStep>>,
  step: FulfillmentStep,
  usertoken: string,
  val: string,
) => {
  useEffect(() => {
    if (!scanDone || val === "" || step !== "SCAN_LABEL") {
      return;
    }

    const validTracking = validateTracking(orderData.tracking, val);
    if (validTracking) {
      logShipped(orderData.orderId, usertoken, (success) => {
        resetKeyEvent();
        if (success) {
          setStep("LABEL_CONFIRMED");
          setError(null);
        } else {
          setStep("SCAN_LABEL");
          setOrderData((data) => ({ ...data, scannedTracking: val }));
          setError("Unable to log kit as shipped.");
        }
      });
    } else {
      // Scan does not match tracking code...
      resetKeyEvent();
      // Scanned a QR code and got a JSON string
      if (/{.*}/i.test(val)) {
        setError(
          `Please scan the barcode on the shipping label for this order before continuing`,
        );
      }
      // Unknown invalid scan
      else {
        setOrderData((data) => ({ ...data, scannedTracking: val }));
        setError(`Tracking number does not match: ${val}`);
      }
    }
  }, [
    clearFilled,
    clearInput,
    orderData.orderId,
    orderData.tracking,
    resetKeyEvent,
    scanDone,
    setError,
    setOrderData,
    setStep,
    step,
    usertoken,
    val,
  ]);
};

/** Wait 3 seconds after label is confirmed to show success messages before resetting app state */
const useLabelConfirmed = (reset: () => void, step: FulfillmentStep) => {
  const onLabelConfirmStep = step === "LABEL_CONFIRMED";
  useTimeout(reset, onLabelConfirmStep, 3000);
};

type FulfillmentStep = "SCAN_BOOKLET" | "SCAN_LABEL" | "LABEL_CONFIRMED";
const useFulfillment = () => {
  const [{ usertoken }] = useLogin();
  const { val, filled: scanDone, clearInput, clearFilled } = useKeyEvent(true);
  const [orderData, setOrderData] = useState<OrderData>(OrderDataInitialState);
  const [errorMessage, setError] = useState<string>(null);
  const [step, setStep] = useState<FulfillmentStep>("SCAN_BOOKLET");
  const [modal, setModal] = useState(false);

  const toggleModal = () => setModal((prev) => !prev);

  /** Reset useKeyEvent hook to scan again */
  const resetKeyEvent = useCallback(() => {
    clearInput();
    clearFilled();
  }, [clearInput, clearFilled]);
  const reset = () => {
    resetKeyEvent();
    setStep("SCAN_BOOKLET");
    setOrderData(OrderDataInitialState);
    setError(null);
    setModal(false);
  };

  useDymoService(() => {});

  useScanBooklet(
    resetKeyEvent,
    scanDone,
    setError,
    setOrderData,
    setStep,
    step,
    usertoken,
    val,
    toggleModal,
  );
  useScanTracking(
    clearFilled,
    clearInput,
    orderData,
    resetKeyEvent,
    scanDone,
    setError,
    setOrderData,
    setStep,
    step,
    usertoken,
    val,
  );
  useLabelConfirmed(reset, step);

  return { orderData, errorMessage, step, val, reset, modal };
};

const getStepHeaders = (step: FulfillmentStep) => {
  switch (step) {
    case "SCAN_BOOKLET":
      return {
        h1: "Scan Welcome Guide",
        h2: "Scan the QR code on the back cover to print a shipping label",
      };
    case "SCAN_LABEL":
      return {
        h1: "Scan Shipping Label",
        h2: "Scan the shipping label to confirm shipment.",
      };
    case "LABEL_CONFIRMED":
      return {
        h1: "Tracking Confirmed",
        h2: "Order initial kit shipped.",
      };
  }
};

export const Fulfillment = () => {
  const { orderData, errorMessage, step, modal, reset } = useFulfillment();
  const { h1, h2 } = getStepHeaders(step);

  return (
    <>
      {modal && (
        <>
          <div className="fulfillment__overlay" />
          <section className="fulfillment__modal">
            <section className="fulfillment__modal-error__wrapper">
              <div className="fulfillment__modal-error__header">Stop</div>
              <div className="fulfillment__modal">Canceled Order Scanned</div>
              <div className="fulfillment__modal">
                <p>Please bring to Cancel Bin.</p>
              </div>
              <div>
                <BaseButton className="go-again__buttons again" onClick={reset}>
                  OK
                </BaseButton>
              </div>
            </section>
          </section>
        </>
      )}
      <main className="fulfillment__main">
        <h1>{h1}</h1>
        <h2>{h2}</h2>
        <section className="fulfillment__scan-wrapper">
          <section className={"fulfillment__kit-data-header"}>
            <h3>Order ID</h3>
            <h3>Tracking</h3>
          </section>
          <ScanRow
            primary={{
              text: orderData.orderId,
              err: errorMessage !== null,
            }}
            secondary={{
              text: orderData.scannedTracking,
              err: errorMessage !== null,
            }}
            onClick={() => {}}
          />
        </section>
        {errorMessage && (
          <article className="fulfillment__error">{errorMessage}</article>
        )}
        <article className="fulfillment__kit">{orderData.kitName}</article>
        {orderData?.shippingType === "express" && (
          <article className="fulfillment__shipping-type">
            {"Express Shipping"}
          </article>
        )}
      </main>
    </>
  );
};

export default Fulfillment;
